import React, { memo, useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { ActivityIndicator, TouchableOpacity, Text } from "react-native-web";
import { fetchAvailablePractitionerFromApi, getPractitionerClean } from "../../../shared/actions/GetAvailablePractitionerAction";
import { fetchRolesFromApi } from "../../../shared/actions/GetRolesAction";
import { fetchAppointmentsTypesFromApi } from "../../../shared/actions/GetAppointmentsTypeAction";
import { fetchTestAllergy, clenupbooking } from '../../../shared/actions/BookAppActions'
import { GetDayName, GetAppointmentDate, GetMonthName, ReturnTypeIcon, GetYear, FormateUTCTime, FormatAMPM } from '../../../shared/core/datehelper';
import { addPaitentAppoinmenttoAPI, clenuplatData } from '../../../shared/actions/AddApointmentActions';
import { Space, Tooltip } from 'antd';
import { Modal, Button, Checkbox, Radio } from 'antd';
import renderHTML from 'react-render-html';
import { Select } from 'antd';
import 'antd/dist/antd.css';
// import $ from "jquery";
import { fetchfAppointmentsFromApi } from "../../../shared/actions/FutureAppointActions";
import getUserId from '../../../shared/core/GetToken';
//import GetAppSettings from '../../../shared/core/GetAppSettings';
import Settings from '../../../shared/config/Settings';
import { fetchProviderLocationFromApi } from "../../../shared/actions/GetProviderLocationAction";
import { fetchLookupListAPI } from "../../../shared/actions/LookUpActions";
import GetAppSettings from '../../../shared/core/GetAppSettings';
import { notification, } from 'antd';



import moment from 'moment';
import { debounce, select } from 'underscore';
import CalendarComponent from '../../components/CalenderControl/CalenderComponent';
import { appointSlotSelectedAdder, appointSlotSelectedRemover } from '../../assets/AppointmentsExternalScripts';
import { actionAPI } from '../../../shared/reduxToolkit/actionAPI';
let apiCounter = 0;
let maxdate = '';
let morning = false;
let evening = false;
let children = [];
let allist = [];
let str = '';
let listofsymptoms = ""
let travel = ""
let contact = "";
const NewUpdatedAppointment = props => {
    const { FutureAppointments, isGettingFut } = useSelector(state => state.FutureAppointments);
    const { AppointmentTypes, isLoadingAPPType, error } = useSelector(state => state.AppointmentTypes)
    const { ProviderLocation, defaultLocation, Colorlist, isFetchingProvider } = useSelector(state => state.ProviderLocations)
    const { LookUpList, isFetchingLookup } = useSelector(state => state.ReasonsForVisit)
    const [portalSetting, setPortalSetting] = useState(null);
    const { AvailablePractitioner, isGetting } = useSelector(state => state.AvailablePractitioners);
    const { Roles, isFetching } = useSelector(state => state.ProviderRoles);
    /////////////Booking 
    const { BookApp, BookAppPM, isBooking, sameDayErrorMsg } = useSelector(state => state.AppointmentSlots);
    const { AddAppointment, isAdding, AddingFailed, isAvailable } = useSelector(state => state.AddPatientAppointment);

    const [appointSlots, setAppointSLots] = useState({
        AMslots: [],
        PMslots: [],
        duration: 0
    })
    const [slotId, setSlotId] = useState('');
    const [selectedSlotData, setSelectedSlotData] = useState({});

    const [showReset, setShowReset] = useState(false);
    const [appointTime, setAppointTime] = useState('');
    const [durationAppoint, setdurationAppoint] = useState('');
    const [doctorName, setDoctorName] = useState('');
    const [loadingDoctors, setLoadingDoctors] = useState(false);
    const [loadingSlots, setLoadingSlots] = useState(false);
    const [Pid, SetPid] = useState('')
    // const [pageSize, setPageSize] = useState(100);           //state for the page number
    // const [pageNumber, setPageNumber] = useState(1);           //state for the page number
    const [isselected, setIsSelected] = useState(false);
    const [isselectedreason, setIsSelectedReason] = useState(false); //state for the
    const [isSelectedBook, setIsSelectedBook] = useState(true);
    const [selectedDate, setSelectedDate] = useState(moment(new Date())); //state for the
    const [availableDay, setAvailableDay] = useState('')
    const [typeid, setTypeId] = useState('')
    const [typeName, setTypeName] = useState('')


    const [AvailableDateList, setAvailableDateList] = useState([]) //store the dates
    const [apiCalled, setApiCalled] = useState(false);
    const [isCovidModalVisible, setIsCovidModalVisible] = useState(false);
    // const [appSettings, setAppSettings] = useState(null)
    // const [travelcheck, setTravelCheck] = React.useState(0);
    // const [contactCheck, setContactCheck] = React.useState(0);
    const [bookingtype, setBookingType] = React.useState(0);
    const [locationName, setLocationName] = useState('')
    const [value, setValue] = React.useState(1);
    const [user, setUser] = React.useState(null)

    const [selectedReasonForVisitId, setSelectedReasonForVisitId] = useState(25)


    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);           //state for the page number
    const [loadingdate, setLoadingDate] = useState(false)
    const [newDate, setNewDate] = useState('')
    const [roleid, setRoleId] = useState("")
    const [roleName, setRoleName] = useState('')

    const { Option } = Select;

    const [nosymptoms, setNoSymptom] = useState(false)
    const [symptoms, setSymptoms] = useState('')
    const [confirmModalvisible, setConfirmModalVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);

    const [confirmModalvisible2, setConfirmModalVisible2] = useState(false);
    const [confirmLoading2, setConfirmLoading2] = React.useState(false);
    const [appointmenttype2, setAppointmenttype2] = useState('')
    const [currentUser, setCurrentUser] = React.useState('');
    const [PaitientID, setPaitientID] = React.useState('');

    const [coivdDose, setCovidDose] = useState(0)
    const [wearMask, setWearMask] = useState(0)
    const [PracticeLocationID, setPracticeLocation] = useState('')
    const [ready, setReady] = useState(false)
    const [ErrorModal, setErrorModal] = useState(false)

    const [handleGotoDate2, setHandleGotoDate2] = useState();
    const [familyMemberBookList, setFamilyMemberBookList] = useState([]);
    const [reasonForContactAlertMsgModalOpen, setReasonForContactAlertMsgModalOpen] = useState(false);
    const [reasonForContactAlertMsg, setReasonForContactAlertMsg] = useState("");
    const [familyMemberSelectErr, setFamilyMemberSelectErr] = useState(false);
    const [lockUnlockSlotResponse, setLockUnlockSlotResponse] = useState(false);
    const [telemedicinePartnerData, setTelemedicinePartnerData] = useState([]);
    const [isTeleHealthDataLoading, setIsTeleHealthDataLoading] = useState(false);
    const [selectedTelemedicinePartner, setSelectedTelemedicinePartner] = useState(null);
    const [teleHealthValidateResponse, setTeleHealthValidateResponse] = useState(null);
    const [consentTeleHealthModalVisible, setConsentTeleHealthModalVisible] = useState(false);

    const apiDispatcher = useDispatch()
    const onBookForChange = e => {
        setValue(e.target.value);
    };
    const onBookingTypeChange = e => {
        setBookingType(e.target.value);
    };
    // const onTravelChange = e => {
    //     setTravelCheck(e.target.value);
    // };
    // const onContactWithSickChange = e => {
    //     setContactCheck(e.target.value);
    // };

    const onChangeConsentA = e => {
        setCovidDose(e.target.value)
    }

    const onChangeConsentB = e => {
        setWearMask(e.target.value)
    }
    const LockUnlockSlot = (slotId, isLock = false) => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("token", item.token);
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify({
                    PatientID: item.userid,
                    SlotID: slotId,
                    Islocked: isLock,
                })
            };

            fetch(Settings.apiUrl + "Patient/LockUnLockSlot", requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLockUnlockSlotResponse(result)
                    if (isLock === true) {
                        if (result === -3) {
                            openNotificationWithIcon("error", "This slot is already locked by another patient")
                            return
                        }

                        if (portalSetting && portalSetting.PortalTurnOffCovidQuestionnaire) {
                            setConfirmModalVisible(true)

                        } else {
                            setIsCovidModalVisible(true);

                        }
                    }
                })
        })
    }
    const addClassTest = (val, item, index) => {
        // alert(JSON.stringify(e))
        // $('.timing').removeClass('selected')
        appointSlotSelectedRemover()
        appointSlotSelectedAdder(val)
        // $('#m' + val).addClass('selected')
        //  alert(appSettings)
        console.log("itemmmm", item.resource.id)
        LockUnlockSlot(item.resource.id, true)
        const newItem =
            setAppointTime(FormatAMPM(item.resource?.extension?.find(o => o.url === "StartTimeString")?.valueString));

        setLocationName(ProviderLocation.length > 0 ? ProviderLocation.filter(location => {
            if (location.resource.extension[0].valueString == item.resource.extension[5].valueString)
                return true
        })[0].resource.extension[1].valueString : '')


        //if true then direct confirmTION modal
        if (portalSetting && portalSetting.AppointmentShowReasonforContact) {
            // setConfirmModalVisible(true)
            setIsSelectedReason(true)

            // setSlotId(item.resource.extension[0].valueString);
        }
        else {
            setIsSelectedReason(true)
        }

        if (portalSetting && portalSetting.PortalTurnOffCovidQuestionnaire) {
            // setConfirmModalVisible(true)
            setSlotId(item.resource.id);
            setSelectedSlotData(item)

        } else {
            // setIsCovidModalVisible(true);
            setSlotId(item.resource.id);
            setSelectedSlotData(item)
        }
    }
    const manageDisplayedSLots = (duration) => {
        let amSlotsArr = [];
        let pmSlotsArr = [];
        var processedSlotsArr = [];
        duration = duration - 1;
        let wholeSlotsArr = []
        if (BookApp && BookAppPM) {
            wholeSlotsArr = wholeSlotsArr.concat(BookApp, BookAppPM)
        }
        if (wholeSlotsArr.length > 0) {
            wholeSlotsArr.map((slot, index, slotArr) => {
                const prevSlotDuration = processedSlotsArr.length > 0 ? ((new Date(processedSlotsArr[processedSlotsArr.length - 1]?.resource?.extension?.find(o => o.url === "EndTimeString")?.valueString) - new Date(processedSlotsArr[processedSlotsArr.length - 1]?.resource?.extension?.find(o => o.url === "StartTimeString")?.valueString)) / 1000) / 60 : 100
                const prevslottimeDiff = processedSlotsArr.length > 0 ? ((new Date(slot?.resource?.extension?.find(o => o.url === "StartTimeString")?.valueString) - new Date(processedSlotsArr[processedSlotsArr.length - 1]?.resource?.extension?.find(o => o.url === "StartTimeString")?.valueString)) / 1000) / 60 : 100

                if (prevSlotDuration >= duration || prevslottimeDiff >= duration) {
                    const thisSlotDuration = ((new Date(slot?.resource?.extension?.find(o => o.url === "EndTimeString")?.valueString) - new Date(slot?.resource?.extension?.find(o => o.url === "StartTimeString")?.valueString)) / 1000) / 60
                    // console.log("thisSlotDuration", thisSlotDuration)
                    if ((thisSlotDuration >= duration && thisSlotDuration <= duration + 2)) {
                        processedSlotsArr.push(slot)
                    } else if (thisSlotDuration <= duration + 2) {
                        let isNextSlotAdjacent = false
                        if (duration > 5) {
                            if (index === wholeSlotsArr.length - 1) {
                                isNextSlotAdjacent = false
                            } else {
                                const nextSlotTimeDiff = ((new Date(slotArr[index + 1]?.resource?.extension?.find(o => o.url === "StartTimeString")?.valueString) - new Date(slot?.resource?.extension?.find(o => o.url === "EndTimeString")?.valueString)) / 1000)
                                const nextSlotDuration = ((new Date(slotArr[index + 1]?.resource?.extension?.find(o => o.url === "EndTimeString")?.valueString) - new Date(slot?.resource?.extension?.find(o => o.url === "StartTimeString")?.valueString)) / 1000) / 60
                                if (nextSlotTimeDiff === 1) {
                                    isNextSlotAdjacent = true
                                    if (nextSlotDuration > duration + 2) {
                                        isNextSlotAdjacent = false

                                    }
                                    if (duration > 30 && nextSlotDuration < duration) {
                                        if (index >= wholeSlotsArr.length - 2) {

                                            isNextSlotAdjacent = false

                                        } else {
                                            const nextSecSlotTimeDiff = ((new Date(slotArr[index + 2]?.resource?.extension?.find(o => o.url === "StartTimeString")?.valueString) - new Date(slotArr[index + 1]?.resource?.extension?.find(o => o.url === "EndTimeString")?.valueString)) / 1000)
                                            const nextSecSlotDuration = ((new Date(slotArr[index + 2]?.resource?.extension?.find(o => o.url === "EndTimeString")?.valueString) - new Date(slotArr[index]?.resource?.extension?.find(o => o.url === "StartTimeString")?.valueString)) / 1000) / 60
                                            if (nextSecSlotTimeDiff === 1) {

                                                isNextSlotAdjacent = true
                                                if (nextSecSlotDuration > duration + 2) {
                                                    isNextSlotAdjacent = false

                                                }

                                                if (duration > 45 && nextSecSlotDuration < duration) {
                                                    if (index >= wholeSlotsArr.length - 3) {
                                                        isNextSlotAdjacent = false

                                                    } else {
                                                        const nextThirdSlotTimeDiff = ((new Date(slotArr[index + 2]?.resource?.extension?.find(o => o.url === "StartTimeString")?.valueString) - new Date(slotArr[index + 1]?.resource?.extension?.find(o => o.url === "EndTimeString")?.valueString)) / 1000)
                                                        if (nextThirdSlotTimeDiff === 1) {
                                                            isNextSlotAdjacent = true
                                                        }
                                                    }
                                                }
                                            } else {
                                                isNextSlotAdjacent = false
                                            }
                                        }
                                    }
                                } else {
                                    isNextSlotAdjacent = false
                                }
                            }
                        }

                        if (isNextSlotAdjacent) {
                            processedSlotsArr.push(slot)
                        }
                    }


                } else {
                    if (processedSlotsArr.length > 0) {

                        // Assuming processedSlotsArr is your original array
                        const lastObjectIndex = processedSlotsArr.length - 1;
                        const lastObject = processedSlotsArr[lastObjectIndex];

                        // Update 'id' property
                        const updatedResource = {
                            ...lastObject.resource,
                            id: lastObject.resource.id + ", " + slot.resource.id,
                            end: slot?.resource?.extension?.find(o => o.url === "EndTimeString")?.valueString,
                            start: lastObject?.resource?.extension?.find(o => o.url === "StartTimeString")?.valueString
                        };

                        // Update 'duration' property in the 'extension' array
                        updatedResource.extension = updatedResource.extension.map(item => {
                            if (item.url === "Duration") {
                                // Create a shallow copy of the extension item
                                return { ...item, valueDecimal: duration + 1 };
                            }
                            return item;
                        });

                        // Create a new object with both updated 'id' and 'duration'
                        const updatedLastObject = {
                            ...lastObject,
                            resource: updatedResource
                        };

                        // Create a new array with the updated last object
                        const updatedSlotsArr = [
                            ...processedSlotsArr.slice(0, lastObjectIndex),
                            updatedLastObject,
                            ...processedSlotsArr.slice(lastObjectIndex + 1)
                        ];

                        // Now, updatedSlotsArr contains the modified objects with updated 'id' and 'duration'

                        processedSlotsArr = updatedSlotsArr;


                        // lastObject.resource?.extension.map(item => {
                        //     if (item.url === "Duration") {
                        //         item.valueDecimal = duration + 1
                        //     }
                        // })

                    }
                }


            })
            // console.log("processed slot before", processedSlotsArr)

            processedSlotsArr.filter(processedSlot => {
                const thisSlotDuration = ((new Date(processedSlot?.resource?.end) - new Date(processedSlot?.resource?.extension?.find(o => o.url === "StartTimeString")?.valueString)) / 1000) / 60
                // console.log("After This slot duration:", thisSlotDuration, " => duration", duration)
                if (thisSlotDuration < duration || thisSlotDuration > duration + 2) {
                    return false
                } else {
                    return true
                }
            }).map((item, index) => {
                FormatAMPM(item.resource?.extension?.find(o => o.url === "StartTimeString")?.valueString).includes('AM') ?
                    amSlotsArr.push(item) : pmSlotsArr.push(item)
            })
        }

        console.log("pmSlots", processedSlotsArr, " => ", wholeSlotsArr)
        setAppointSLots({
            AMslots: amSlotsArr,
            PMslots: pmSlotsArr,
            duration: duration
        })
    }
    useEffect(() => {
        localStorage.removeItem("prev-selected-reason")
    }, [])
    useEffect(() => {
        if (BookApp && BookApp.length > 0) {

            if (selectedReasonForVisitId === 25) {

                if (localStorage.getItem("prev-selected-reason")) {

                    setSelectedReasonForVisitId(JSON.parse(localStorage.getItem("prev-selected-reason"))?.reasonID)
                }
            }
        }
    }, [BookApp])
    useEffect(() => {

        if ((BookApp.length || BookAppPM.length) && selectedReasonForVisitId !== 25) {
            let duration = 15

            LookUpList?.map(item => {
                if (item.resource.id === selectedReasonForVisitId) {
                    duration = parseInt(item.resource.extension.find(o => o.url === "AppointmentSlot")?.valueString)
                }
            })
            if (!duration) {
                duration = 15
            }
            // if (selectedReasonForVisitName.toLowerCase().indexOf("30 min") >= 0) {
            //     duration = 30
            // }
            // else if (selectedReasonForVisitName.toLowerCase().indexOf("15 min") >= 0) {
            //     duration = 15
            // }
            // else if (selectedReasonForVisitName.toLowerCase().indexOf("45 min") >= 0) {
            //     duration = 45
            // }
            // else if (selectedReasonForVisitName.toLowerCase().indexOf("60 min") >= 0) {
            //     duration = 60
            // }
            // else if (selectedReasonForVisitName.toLowerCase().indexOf("10 min") >= 0) {
            //     duration = 10
            // }
            // else if (selectedReasonForVisitName.toLowerCase().indexOf("20 min") >= 0) {
            //     duration = 20
            // }
            // else if (selectedReasonForVisitName.toLowerCase().indexOf("40 min") >= 0) {
            //     duration = 40
            // }
            // else if (selectedReasonForVisitName.toLowerCase().indexOf("50 min") >= 0) {
            //     duration = 50
            // }
            // else if (selectedReasonForVisitName.toLowerCase().indexOf("55 min") >= 0) {
            //     duration = 55
            // }
            // else if (selectedReasonForVisitName.toLowerCase().indexOf("35 min") >= 0) {
            //     duration = 35
            // }
            // else if (selectedReasonForVisitName.toLowerCase().indexOf("25 min") >= 0) {
            //     duration = 25
            // }
            // else if (selectedReasonForVisitName.toLowerCase().indexOf("5 min") >= 0) {
            //     duration = 5
            // }
            // else {
            //     duration = 15
            // }
            console.log("finallll duration", duration)
            manageDisplayedSLots(duration)

        } else {

            setAppointSLots({
                AMslots: BookApp,
                PMslots: BookAppPM,
                duration: 15
            })
        }
    }, [selectedReasonForVisitId, BookApp, BookAppPM])
    //handleConfirmAppoint()
    const handleCovidModalOk = () => {

        if (portalSetting.DisableAppointmentCOVID === "0") {
            if ((symptoms != '' || nosymptoms) && wearMask !== 0) {

            setConfirmModalVisible(true);
            setIsCovidModalVisible(false);
            } else {
                openNotificationWithIcon("error", "Please fill the mandatory fields")
            }
        }
        else if (portalSetting.DisableAppointmentCOVID === "1") {
            if ((symptoms != '' || nosymptoms) && wearMask !== 0) {

                if (typeid === '1') {

                if (nosymptoms && wearMask == 2) {
                    setConfirmModalVisible(true);
                    setIsCovidModalVisible(false);
                } else {
                    setIsCovidModalVisible(false);
                    setErrorModal(true)
                }
            } else {
                    setConfirmModalVisible(true);
                    setIsCovidModalVisible(false);

                }
            }
            else {
                openNotificationWithIcon("error", "Please fill the mandatory fields")

            }
        }
        else {

            if ((symptoms != '' || nosymptoms) && wearMask !== 0) {

                // if (nosymptoms && contactCheck == 2 && travelcheck == 2) {
                // if (nosymptoms && coivdDose == 2 && wearMask == 2) {
                if (nosymptoms && wearMask == 2) {

                    setConfirmModalVisible(true);
                    setIsCovidModalVisible(false);
                }
                else {
                    if (portalSetting && portalSetting.IsShowBookAppointmentOptions) {
                        listofsymptoms = ""
                        symptoms.map((item, index) => {
                            return (
                                listofsymptoms = listofsymptoms + item + ","
                            )
                        })
                        // travel = travelcheck == 1 ? "Yes \nDetail:" + document.getElementById('mtravel').value : "No"
                        // contact = contactCheck == 1 ? "Yes \nDetail:" + document.getElementById('mcontact').value : "No"
                        // // alert(listofsymptoms + travel + contact)
                        // let message = `Suspected COVID-19 \nSymptoms \nHave you had any of the following?\n${listofsymptoms} \nRecent travel or contact with someone with novel coronavirus \n a) Have you travelled to or from (excluding airport transit through) countries or areas of concern* within 14 days before onset of illness? ${travel}\nb) Have you been in close contact or casual contact** with a person with suspect, probable or confirmed infection of novel coronavirus in the 14 days before the onset of your symptoms? ${contact}`;
                        // document.getElementById('mReason').value = message
                        // setIsCovidModalVisible(false);
                        // setConfirmModalVisible2(true);
                        setErrorModal(true)
                    } else {
                        // setIsCovidModalVisible(false);
                        // alert("hihihi")
                        // setConfirmModalVisible(true);
                        // document.getElementById('mReason').value = "As per the responses recorded through this questionnaire, your appointment cannot be booked. For further assistance please contact the practice to organize/manage your appointment."
                        setIsCovidModalVisible(false);
                        // setConfirmModalVisible(true);
                        setErrorModal(true)
                    }

                }

            } else {
                openNotificationWith('error')
            }
        }

    };
    const handleCovidModalCancel = () => {
        LockUnlockSlot(slotId, false)

        setIsCovidModalVisible(false);
        setWearMask(0)
        setCovidDose(0)
        setNoSymptom(false)
        setSymptoms("")
        // setContactCheck(0)
        // setTravelCheck(0)
    };

    const openNotificationWith = type => {
        notification[type]({
            message: 'Notification',
            description:
                'Please fill  mandatory fields',
        });
    };
    const openNotificationWithDynamic = (type, value) => {
        notification[type]({
            message: 'Notification',
            description:
                value,
        });
    };
    const openNotificationWithIcon = (type, msg = 'No More Slots Available for next Day') => {
        notification[type]({
            message: 'Notification',
            description:
                msg,
        });
    };

    const selectedDay = val => {
        let valLocation = '';
        defaultLocation.map((item, index) => {
            return (
                valLocation = valLocation + item + ","
            )
        })

        setNewDate('')

        setApiCalled(false)
        apiCounter = 0;

        // let dateArr = selectedDate?.split("/")
        // let date = `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}`


        apiDispatcher(actionAPI.GetAppointmentSlotsFromAPI({
            passingDate: moment(selectedDate).format("YYYY-MM-DD"),
            practitionerId: Pid,
            typeid: typeid,
            LocationIDs: valLocation,
            sameDayBooking: portalSetting?.IsAllowSameDayBooking2
        }))
        apiDispatcher(actionAPI.AppointmentSlotsDataCleanup())
        setIsSelectedReason(false);
    };
    useEffect(() => {
        if (selectedDate) {
            selectedDay()
        }
    }, [selectedDate])
    function onBookWithChange(value, index) {
        // alert(`selected ${ value } `);
        setLoadingDoctors(true)
        setIsSelected(false)
        setRoleId(value)
        setRoleName(index.children)
        SetPid("")
        apiDispatcher(actionAPI.AvailablePractitionersDataCleanup())
        if (value !== "") {


            apiDispatcher(actionAPI.GetAvailablePractitionersFromAPI({
                roleID: value,
                appType: typeid,
                roleType: Roles[0].resource.extension[2].valueString,
                locations: PracticeLocationID,
                selectedReasonForVisit: selectedReasonForVisitId
            }))
        }


    }

    function onSelectProviderChange(value) {
        let valLocation = '';
        defaultLocation.map((item, index) => {
            return (
                valLocation = valLocation + item + ","
            )
        })
        SetPid(value)
        // let dateArr = selectedDate?.split("/")
        // let date = `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}`
        if (value !== "") {
            setIsSelected(true)

            apiDispatcher(actionAPI.GetAppointmentSlotsFromAPI({
                passingDate: moment(selectedDate).format("YYYY-MM-DD"),
                practitionerId: value,
                typeid: typeid,
                LocationIDs: valLocation,
                sameDayBooking: portalSetting?.IsAllowSameDayBooking2
            }))

        } else {
            setIsSelected(false)
        }

    }
    function onAppointTypeSelectChange(value, children) {
        setTypeName(children.label)
        setTypeId(value)
    }
    function onAppointTypeSelectChange2(value) {

        // alert(`selected ${ value }`)
        setAppointmenttype2(value)


    }
    function onSelectAccountChange(value) {
        //alert(value)
        setPaitientID(value)
        setFamilyMemberSelectErr(false)


    }



    function replaceAllBackSlash(targetStr) {
        var index = targetStr.indexOf("\\");
        while (index >= 0) {
            targetStr = targetStr.replace("\\", "");
            index = targetStr.indexOf("\\");
        }
        return targetStr;
    }

    const handleCloseReset = () => {
        setShowReset(false)
        apiDispatcher(actionAPI.AddPatientAppointmentRefreshData())
        // testFunction(AuthUser[0].extension[0].valueString, AuthUser[0].id)

    };

    const handleConfirmAppoint = () => {
        let reason = document.getElementById('mReason').value;
        // if (reason == '') {
        //     reason = ""
        // }
        /////////////////here we need 
        // alert(selectedReasonForVisitId)
        // if (portalSetting.AppointmentShowReasonforContact && selectedReasonForVisitId === 25) {
        //     openNotificationWithIcon("error", "Reason for visit is required")
        //     setConfirmModalVisible(false)
        //     return
        // }
        if (portalSetting.PortalTurnOffCovidQuestionnaire) {
            reason = reason
        }

        else if (portalSetting.DisableAppointmentCOVID === "0") {
            reason = reason + " Symptoms: " + symptoms
        }
        else {
            reason = reason + " No Symptoms"

        }

        if (value == 1)

            apiDispatcher(actionAPI.AddPatientAppoinmenttoAPI({
                slotid: slotId,
                reason: reason,
                PaitentID: '',
                typeid: typeid,
                reasonId: selectedReasonForVisitId,
                startTime: selectedSlotData?.resource?.start,
                endTime: selectedSlotData?.resource?.end,
                duration: selectedSlotData?.resource?.extension?.find(o => o.url === "Duration")?.valueDecimal
            }))
        else if (value == 2)
            if (PaitientID === "") {

                setFamilyMemberSelectErr(true)
                openNotificationWithIcon("error", "Please select a family member above")
                setConfirmLoading(false)
                setConfirmLoading2(false)
                setConfirmModalVisible(false)
            } else {

                apiDispatcher(actionAPI.AddPatientAppoinmenttoAPI({
                    slotid: slotId,
                    reason: reason,
                    PaitentID: PaitientID,
                    typeid: typeid,
                    reasonId: selectedReasonForVisitId,
                    startTime: selectedSlotData?.resource?.start,
                    endTime: selectedSlotData?.resource?.end,
                    duration: selectedSlotData?.resource?.extension?.find(o => o.url === "Duration")?.valueDecimal
                }))
            }
        else {

            apiDispatcher(actionAPI.AddPatientAppoinmenttoAPI({
                slotid: slotId,
                reason: reason,
                PaitentID: '',
                typeid: typeid,
                reasonId: selectedReasonForVisitId,
                startTime: selectedSlotData?.resource?.start,
                endTime: selectedSlotData?.resource?.end,
                duration: selectedSlotData?.resource?.extension?.find(o => o.url === "Duration")?.valueDecimal
            }))
        }
    }
    const onLookupChange = (e, option) => {
        localStorage.setItem("prev-selected-reason", JSON.stringify({
            reasonID: e,
            reasonName: option?.children
        }))
        setSelectedReasonForVisitId(e);
        let valLocation = '';
        defaultLocation.map((item, index) => {
            return (
                valLocation = valLocation + item + ","
            )
        })
        // let dateArr = selectedDate?.split("/")
        // let date = `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`

        apiDispatcher(actionAPI.GetAppointmentSlotsFromAPI({
            passingDate: moment(selectedDate).format("YYYY-MM-DD"),
            practitionerId: Pid,
            typeid: typeid,
            LocationIDs: valLocation,
            sameDayBooking: portalSetting?.IsAllowSameDayBooking2
        }))
        let alertMsg = ""
        LookUpList?.map(item => {
            if (item.resource.id === e) {
                alertMsg = item.resource.extension.find(o => o.url === "AlertMsg")?.valueString
            }
        })
        if (e !== 25 && alertMsg && alertMsg?.trim() !== "") {
            setReasonForContactAlertMsg(alertMsg)
            setReasonForContactAlertMsgModalOpen(true)
        }
    }

    let ignoredNote = 1;
    const appointBookSuccess = () => {
        //    dispatch(fetchCounterFromApi())
        if (ignoredNote == 1) {
            appsuccessnoti('success')
            let valLocation = '';
            defaultLocation.map((item, index) => {
                return (
                    valLocation = valLocation + item + ","
                )
            })
            // let dateArr = selectedDate?.split("/")
            // let date = `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`

            apiDispatcher(actionAPI.GetAppointmentSlotsFromAPI({
                passingDate: moment(selectedDate).format("YYYY-MM-DD"),
                practitionerId: Pid,
                typeid: typeid,
                LocationIDs: valLocation,
                sameDayBooking: portalSetting?.IsAllowSameDayBooking2
            }))
            // setPaitientID('')
        }
        ignoredNote = ignoredNote + 1;

        apiDispatcher(actionAPI.AddPatientAppointmentRefreshData())
        // setTimeout(() => {
        //     window.location.reload();
        // }, 1500);

        // props.navigation.navigate('Appnav')
    }
    const appointBookFailed = () => {
        //    dispatch(fetchCounterFromApi())
        failNoti('error')
        setConfirmLoading2(false);
        apiDispatcher(actionAPI.AddPatientAppointmentRefreshData())

        setConfirmLoading(false)
        setConfirmModalVisible(false)
        //setConfirmLoading2(true)
        setConfirmModalVisible2(false)
        // setTimeout(() => {
        //     window.location.reload();
        // }, 1500);

        // props.navigation.navigate('Appnav')
    }
    const appsuccessnoti = type => {
        notification[type]({
            message: 'Notification',
            description:
                'Appointment Booked successfully',
        });
    };
    const failNoti = type => {
        let message = AddAppointment.AppoinmentStatus
        if (AddAppointment.AppoinmentStatus == "NotCreatedEmailMissing") {
            message = "Zoom email account does not exist for provider of this patient"
        } else if (AddAppointment.AppoinmentStatus == "NotCreatedZoomEnd") {
            message = "Unable to book appointment due to issue at Zoom end"
        } else if (typeof (AddAppointment) == "string") {
            message = AddAppointment
        }
        openNotificationWithDynamic("error", message)
        // notification[type]({
        //     message: 'Notification',
        //     description:
        //         message,
        //     type: type

        // });
    };

    function handleChangeLocation(value) {
        setPracticeLocation(value)

        apiDispatcher(actionAPI.GetRolesFromAPI({ PracticeLocation: value }))
        // props.getPractitioner(roleid, typeid, Roles[0].resource.extension[2].valueString, value)

        apiDispatcher(actionAPI.AppointmentSlotsDataCleanup())
    }
    const getCurrentUserId = () => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            // name = item.parentname;

            setCurrentUser(item.userid)
            setCurrentUser((state) => {
                return state;
            });
        })
        return currentUser;
    }
    const confirmAppointHandleOk = () => {

        // setModalText('The modal will be closed after two seconds')

        handleConfirmAppoint()
        setConfirmLoading(true);
    };
    const confirmhandleCancel = () => {
        setConfirmModalVisible(false);
    };
    const confirmAppointHandleOk2 = () => {

        // setModalText('The modal will be closed after two seconds');
        if (portalSetting.DisableAppointment) {
            openNotificationWithDynamic('error', "Please ring the practice to organise an appointment")
            setConfirmLoading2(true);
            setConfirmModalVisible2(false)
        }
        else {
            if (bookingtype == 2) {
                handleConfirmAppoint()
                setConfirmLoading2(true);
            }
            else {
                if (appointmenttype2 != '') {
                    setConfirmLoading2(true);
                    handleConfirmAppoint()
                }
                else {
                    openNotificationWith('warning')
                }
            }
        }
        // setTimeout(() => {
        //     // setVisible(false);
        //     setConfirmLoading(false);
        // }, 2000);
    };
    const confirmhandleCancel2 = () => {
        setConfirmModalVisible2(false);
    };
    function onChangeSymptom(checkedValues) {
        setSymptoms(checkedValues)
        // alert(checkedValues.length)
        if (checkedValues.length)
            setNoSymptom(false)
    }
    //onChangeNoSympton
    function onChangeNoSympton(checkedValues) {
        setNoSymptom(checkedValues)

        if (checkedValues.target.checked)
            onChangeSymptom([])
    }
    const getPartnerTeleHealthDataFromApi = () => {
        setIsTeleHealthDataLoading(true)
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("token", item.token);
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify({
                    PatientUUID: item.userid
                })
            };

            fetch(Settings.apiUrl + "Patient/PartnerTeleHealthGet", requestOptions)
                .then(response => response.json())
                .then(result => {
                    setIsTeleHealthDataLoading(false)
                    setTelemedicinePartnerData(result)
                })
        })
    }

    const validatePartnerTeleHealth = (partnerUUID = "") => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("token", item.token);
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify({
                    PartnerUUID: partnerUUID,
                    PatientUUID: item.userid
                })
            };

            fetch(Settings.apiUrl + "Patient/PartnerTeleHealthValidate", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result?.Success) {

                        setConsentTeleHealthModalVisible(true)
                        setTeleHealthValidateResponse(result)
                    } else {
                        if (typeof result === "string") {

                            openNotificationWithIcon("error", result)
                        }
                        else {
                            openNotificationWithIcon("error", "Unable to validate partner request, please contact your practice for further assistance.")
                        }
                    }
                })
        })
    }
    useEffect(() => {
        apiDispatcher(actionAPI.GetProviderLocationsFromAPI())

        setPortalSetting(JSON.parse(localStorage.getItem("@portalSettings")))
        // GetAppSettings().then((info) => {
        //     const set = JSON.parse(info)
        // })

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            setUser(item)
        })

        apiDispatcher(actionAPI.GetAppointmentTypesFromAPI())
        getPartnerTeleHealthDataFromApi()
        var now = new Date();
        maxdate = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0).toLocaleString()).add(60, 'days')
    }, []);

    useEffect(() => {
        apiDispatcher(actionAPI.GetReasonsForVisitFromAPI())
        // if (portalSetting && portalSetting.ReasonForContact) {
        // }
    }, [portalSetting])
    useEffect(() => {
        if (defaultLocation != '') {
            setPracticeLocation(defaultLocation)

            apiDispatcher(actionAPI.GetRolesFromAPI({
                PracticeLocation: defaultLocation
            }))
        }
    }, [defaultLocation]);

    useEffect(() => {
        if (AppointmentTypes && AppointmentTypes.length > 0) {
            setTypeId(AppointmentTypes[0].resource.extension[0].valueString)
            setTypeName(AppointmentTypes[0].resource.extension[1].valueString)
        }
    }, [AppointmentTypes])



    useEffect(() => {
        if (portalSetting && portalSetting.ReasonForContact && value && typeid && Roles && Roles.length && PracticeLocationID)
            if (roleid && roleid !== "") {


                apiDispatcher(actionAPI.GetAvailablePractitionersFromAPI({
                    roleID: roleid,
                    appType: typeid,
                    roleType: Roles[0]?.resource?.extension[2]?.valueString,
                    locations: PracticeLocationID,
                    selectedReasonForVisit: selectedReasonForVisitId
                }))

            } else {

                apiDispatcher(actionAPI.GetAvailablePractitionersFromAPI({
                    roleID: Roles[0]?.resource?.id,
                    appType: typeid,
                    roleType: Roles[0]?.resource?.extension[2]?.valueString,
                    locations: PracticeLocationID,
                    selectedReasonForVisit: selectedReasonForVisitId
                }))
            }
    }, [selectedReasonForVisitId, Roles]);


    // useEffect(() => {
    //     if (Roles && Roles.length > 0) {
    //         // setRoleId(Roles[0].resource.extension[0].valueString)
    //         // roleID, appType, roleType, locations, selectedReasonForVisitId
    //         if (typeid && PracticeLocationID) {

    //             apiDispatcher(actionAPI.GetAvailablePractitionersFromAPI({
    //                 roleID: Roles[0].resource.id,
    //                 appType: typeid,
    //                 roleType: Roles[0].resource.extension[2].valueString,
    //                 locations: PracticeLocationID,
    //                 selectedReasonForVisit: selectedReasonForVisitId
    //             }))
    //         }
    //     }

    // }, [Roles])

    useEffect(() => {
        if (AddAppointment == "Appointment has been booked successfully") {
            appointBookSuccess()
            setConfirmLoading2(false);
            setConfirmModalVisible(false)
            setConfirmLoading(false);
        }
    }, [AddAppointment])
    useEffect(() => {
        AddingFailed &&
            appointBookFailed()
    }, [AddingFailed])
    useEffect(() => {
        if (ProviderLocation && ProviderLocation.length > 0) {

            for (let i = 0; i < ProviderLocation.length; i++) {

                let obj = {
                    id: ProviderLocation[i].resource.extension[0].valueString,
                    colorcode: Math.floor(Math.random() * 16777215).toString(16),
                    name: ProviderLocation[i].resource.extension[1].valueString
                }
                children.push(obj);
                allist.push(ProviderLocation[i].resource.extension[0].valueString)
                //  if (!str.includes(ProviderLocation[i].resource.extension[0].valueString)) {
                str = str + ProviderLocation[i].resource.extension[0].valueString + ","
                //   }


            }
            setReady(!ready)
        }


    }, [ProviderLocation])

    // useEffect(() => {
    //     if (AvailablePractitioner && AvailablePractitioner.length > 0) {
    //         SetPid(AvailablePractitioner[0].resource.id)
    //         setIsSelected(true)
    //         // commenting now for test
    //         //      props.getApp(GetAppointmentDate(selectedDate) + ' ' + GetMonthName(selectedDate) + ' ' + GetYear(selectedDate), AvailablePractitioner[0].resource.id, typeid, true);
    //     }
    //     else {
    //         setIsSelected(false);
    //         SetPid("")
    //     }
    // }, [AvailablePractitioner])

    useEffect(() => {
        //alert('dsdssd')
        let val = '';
        defaultLocation.map((item, index) => {
            return (
                val = val + item + ","
            )
        })
        if (Pid != '' && typeid != '' && roleid != '' && portalSetting) {

            apiDispatcher(actionAPI.GetAppointmentSlotsFromAPI({
                passingDate: moment(selectedDate).format("YYYY-MM-DD"),
                practitionerId: Pid,
                typeid: typeid,
                LocationIDs: val,
                sameDayBooking: portalSetting?.IsAllowSameDayBooking2
            }))
        }
        return apiDispatcher(actionAPI.AppointmentSlotsDataCleanup())
    }, [typeid, defaultLocation])
    // useEffect(() => {
    //     if (Proxy && Proxy.length > 1) {
    //         setPaitientID(Proxy[1].resource.extension[0].valueString)

    //     }
    // }, [Proxy])
    useEffect(() => {
        if (Roles && Roles.length) {

            // setRoleName(Roles[0].resource.extension[1].valueString)
            // if (typeid)
            // props.getPractitioner(roleid === "" ? Roles[0]?.resource?.extension[0]?.valueString : roleid, typeid, Roles[0]?.resource?.extension[2]?.valueString, defaultLocation, selectedReasonForVisitId)
        }
    }, [Roles, typeid])
    useEffect(() => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("token", item.token);
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify({
                    PatientID: item.userid,
                    locationID: 0
                })
            };

            fetch(Settings.apiUrl + "Patient/GetPatientFamilyBooking", requestOptions)
                .then(response => response.json())
                .then(result => {
                    // if (result.length === 1) {
                    //     setPaitientID(result[0]?.PatientIDUUID)
                    // }

                    setFamilyMemberBookList(result)
                })
        })
    }, [])
    return (
        <div>

            {isSelectedBook &&
                <div className="row row-margin-zero" >
                    <div className="col-md-12 mx-auto">
                        <div className="row mb-2">
                            <div className="col-md-12 mx-auto">
                                <div className="alert alert-warning alert-warning-container" role="alert" >

                                    <h5 className="alert-heading">Disclaimer:</h5>
                                    {portalSetting && portalSetting.PortalAppointmentTermsAndConditions &&
                                        renderHTML(portalSetting.PortalAppointmentTermsAndConditions)
                                    }


                                </div>
                            </div>
                        </div>

                        {!isLoadingAPPType && !isTeleHealthDataLoading ? AppointmentTypes && AppointmentTypes.length ?
                            //AppointmentTypes && AppointmentTypes.length && defaultLocation && Roles && Roles.length && ProviderLocation && ProviderLocation.length && defaultLocation.length ?
                            <>
                                {familyMemberBookList.length > 0 ?
                                    <>
                                        {
                                            familyMemberBookList && familyMemberBookList.length > 0 ?
                                                <>
                                                    <div className="form-group row ">

                                                        <div className="col d-flex flex-column">
                                                            <label className="col-form-label">Book For</label>
                                                            <Radio.Group onChange={onBookForChange} value={value}>
                                                                <Radio value={1}>Self</Radio>
                                                                <Radio value={2}>Family Member</Radio>

                                                            </Radio.Group>
                                                        </div>
                                                    </div>

                                                    {value == "2" &&
                                                        <div className="form-group row ">
                                                            <div className="col">
                                                                {
                                                                    familyMemberBookList && familyMemberBookList.length ?
                                                                        <Select
                                                                            value={PaitientID}

                                                                            defaultActiveFirstOption
                                                                            showSearch
                                                                            className='w-100'
                                                                            status={familyMemberSelectErr ? 'error' : ""}
                                                                            placeholder="Select Account"
                                                                            optionFilterProp="children"
                                                                            onChange={onSelectAccountChange}
                                                                            //onFocus={onFocus}
                                                                            //onBlur={onBlur}
                                                                            //onSearch={onSearch}

                                                                            filterOption={(input, option) =>
                                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 10
                                                                            }
                                                                        >
                                                                            <Option value="">Select</Option>
                                                                            {familyMemberBookList.map((item, index) => {
                                                                                // if (getCurrentUserId() != item.PatientID)
                                                                                return (
                                                                                    <Option value={item.PatientIDUUID}>{item.PatientName}</Option>
                                                                                )
                                                                            })}

                                                                        </Select>
                                                                        : null
                                                                }
                                                                {PaitientID === "" && <small className={`text-danger ${selectedReasonForVisitId != 25 ? 'hideelement' : 'showelement'}`}>Please select a family member</small>}

                                                            </div>
                                                        </div>
                                                    }

                                                </> : null
                                        }
                                    </> : null}



                                <div className='row form-group'>
                                    <div className="col-md-12">
                                        <label className="col-form-label text-bold">Provider Location</label>

                                        {defaultLocation && ProviderLocation && ProviderLocation.length && defaultLocation.length ?
                                            <Select
                                                id="mploc"
                                                mode="multiple"
                                                maxTagCount={5}
                                                allowClear
                                                className='w-100'
                                                placeholder="Please select"
                                                defaultValue={defaultLocation.length ? defaultLocation : []}
                                                onChange={handleChangeLocation}
                                            >
                                                {ProviderLocation.map((item, index) => {
                                                    return (
                                                        <Option key={item.resource.extension[0].valueString}>
                                                            {item.resource.extension[1].valueString}
                                                        </Option>
                                                    )
                                                })}

                                            </Select> : null}


                                    </div>
                                </div>

                                <div className="form-group row ">
                                    {portalSetting && portalSetting.AppointmentShowReasonforContact ? <div className="col-md-4 col-sm-4">
                                        <div className='form-group'>
                                            <label className="col-form-label text-bold">Reason for Appointment</label>
                                            <Select

                                                allowClear
                                                className='w-100'
                                                placeholder="Select"
                                                optionFilterProp="children"
                                                onChange={onLookupChange}

                                                value={selectedReasonForVisitId}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 10
                                                }
                                            >
                                                <Option value={25}>Select reason for appointment</Option>
                                                {LookUpList?.map((data, index) => {
                                                    return (
                                                        <Option value={data.resource.extension.find(o => o.url === "ReasonForContactID")?.valueString}>{`${data.resource.extension.find(o => o.url === "ReasonForContactName")?.valueString}`}</Option>
                                                    )
                                                })}

                                            </Select>
                                            {/* <small className={`text-danger ${selectedReasonForVisitId != 25 ? 'hideelement' : 'showelement'}`}>Reason for appointment is required</small> */}
                                            <Modal
                                                title="Information"
                                                open={reasonForContactAlertMsgModalOpen}
                                                onOk={() => setReasonForContactAlertMsgModalOpen(false)}
                                                cancelButtonProps={{ style: { display: 'none' } }}
                                                maskClosable={false}
                                                closable={false}
                                            >
                                                {reasonForContactAlertMsg}
                                            </Modal>
                                        </div>
                                    </div> : null}

                                    <div className="col-md-4 col-sm-4">
                                        <div className='form-group'>
                                            <label className="col-form-label text-bold">Reason Notes</label>
                                            <textarea autoComplete="off" id="mReason" className="form-control" rows={1} placeholder="Type Reason here..." defaultValue={""} />
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-sm-4">
                                        <div className='form-group'>
                                            <label className="col-form-label text-bold">Appointment Type</label>
                                            {
                                                AppointmentTypes && AppointmentTypes.length ?
                                                    <Select
                                                        defaultValue={AppointmentTypes[0].resource.extension[0].valueString}

                                                        defaultActiveFirstOption
                                                        showSearch
                                                        className='w-100'
                                                        placeholder="Select Appointment type"
                                                        optionFilterProp="children"
                                                        onChange={onAppointTypeSelectChange}
                                                        //onFocus={onFocus}
                                                        //onBlur={onBlur}
                                                        //onSearch={onSearch}
                                                        getPopupContainer={(trigger) =>
                                                            trigger.parentNode
                                                        }
                                                        filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input)}
                                                        filterSort={(optionA, optionB) =>
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        }
                                                        options={AppointmentTypes.map((person) => {
                                                            return {
                                                                value: person.resource.extension[0].valueString,
                                                                label: person.resource.extension[1].valueString
                                                            }

                                                        })}
                                                    />

                                                    : null
                                            }
                                        </div>
                                    </div>





                                    <div className="col-md-4 col-sm-4 showcase_content_area mb-1">
                                        <div className='form-group'>
                                            {
                                                isFetching && isGetting ? null :
                                                    Roles ?
                                                        <>
                                                            <label className="col-form-label text-bold">Book With</label>
                                                            <Select
                                                                // disabled={portalSetting &&
                                                                //     portalSetting.ByPassLockDownPatientPortalAppointmentBookings == false
                                                                //     && AvailablePractitioner.length && AvailablePractitioner[0].resource.extension[3].valueBoolean == true}
                                                                // defaultValue={Roles.length > 0 ? Roles[0].resource.extension[0].valueString : ""}
                                                                //showSearch
                                                                className='w-100'
                                                                placeholder="Select"
                                                                optionFilterProp="children"
                                                                onChange={onBookWithChange}
                                                                //onFocus={onFocus}
                                                                value={roleid}
                                                                //onBlur={onBlur}
                                                                //onSearch={onSearch}
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                                }
                                                            >
                                                                <Option value="">Select</Option>
                                                                {Roles.length > 0 && Roles.slice()?.sort((a, b) => a?.resource?.extension[1]?.valueString > b?.resource?.extension[1]?.valueString ? 1 : -1)?.map((person, index) => {
                                                                    return (
                                                                        <Option value={person.resource.extension[0].valueString}>{person.resource.extension[1].valueString}</Option>
                                                                    )
                                                                })}

                                                            </Select>

                                                        </>
                                                        : null
                                            }
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-sm-4 showcase_content_area mb-1">
                                        <div className='form-group'>
                                            {isGetting ? null
                                                :
                                                <>
                                                    <label className="col-form-label text-bold"> Select GP </label>
                                                    {
                                                        (AvailablePractitioner && AvailablePractitioner.length > 0) || roleid === "" ?
                                                            <Select
                                                                // disabled={portalSetting &&
                                                                //     portalSetting.ByPassLockDownPatientPortalAppointmentBookings == false
                                                                //     && AvailablePractitioner.length && AvailablePractitioner[0].resource.extension[3].valueBoolean == true}
                                                                // defaultValue={AvailablePractitioner.length ? AvailablePractitioner[0].resource.name[0].text : ""}
                                                                value={Pid}
                                                                showSearch
                                                                className='w-100'
                                                                placeholder="Select provider"
                                                                optionFilterProp="children"
                                                                onChange={onSelectProviderChange}
                                                                //onFocus={onFocus}
                                                                //onBlur={onBlur}
                                                                //onSearch={onSearch}
                                                                getPopupContainer={(trigger) =>
                                                                    trigger.parentNode
                                                                }
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                <Option value="">Select Provider</Option>
                                                                {AvailablePractitioner.slice()?.sort((a, b) => a?.resource?.name > b?.resource?.name ? 1 : -1)?.map((person, index) => {
                                                                    return (
                                                                        <Option value={person.resource.id}>{person.resource.name[0].text}</Option>
                                                                    )
                                                                })}

                                                            </Select>
                                                            : !isGetting &&
                                                            <p className='text-danger'>
                                                                {

                                                                        AvailablePractitioner && AvailablePractitioner.length > 0 ? "Please select any Practitioner"
                                                                            : 'Registered Provider is not available'} </p>

                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>

                                </div>

                                <div className="row">

                                    {
                                        isselected && <>
                                            <CalendarComponent
                                                startingMonth={new Date().getMonth() + 1}
                                                startingYear={new Date().getFullYear()}
                                                endingMonth={new Date().getMonth()}
                                                endingYear={new Date().getFullYear() + 2}
                                                setDateState={setSelectedDate}
                                                Pid={Pid}
                                                typeid={typeid}
                                                selectedDateFromC={selectedDate}
                                                locids={defaultLocation.map((item) => item)}
                                                setSelectedReasonForVisitId={setSelectedReasonForVisitId}
                                            />
                                        </>
                                    }

                                </div>


                                {/* {isselected &&
                                    <div className="row form-group "><div className="col-md-12 col-sm-12"><div className="card-body full-width">
                                        <Button className='btn btn-primary btn-sm pull-right' onClick={() => { nextAvailableDay() }}>Next Available Day</Button>
                                    </div></div> </div>} */}





                                {isSelectedBook &&
                                    <>
                                        {
                                            isselected &&
                                            <>

                                                <div className="schedule-cont p-0 mt-2 row">
                                                    <div className="col-md-12 col-sm-12 ">
                                                    <div className="row no-gutters">

                                                        {Colorlist && Colorlist.length > 1 ?
                                                            Colorlist.map((item, i) => {
                                                                return (
                                                                    <div className='d-flex flex-row margin-right-ten margin-bottom-five '>
                                                                        <div className={'w-20px h-20px d-flex appoint-color-code-' + item.colorcode}></div>
                                                                        <span className='margin-left-five' >{item.name}</span>
                                                                    </div>
                                                                )
                                                            }) : null

                                                        }

                                                        <div className="col-md-12">
                                                            <div className="time-slot">
                                                                <ul className="clearfix">
                                                                    <div className="form-group row mb-0">
                                                                        <div className="col-md-6 mt-2 ">
                                                                            <div className='card'>
                                                                                <dv className='card-header'>
                                                                                    <h6 className='mb-0'>Morning</h6>

                                                                                </dv>

                                                                                <div className='card-body p-2'>
                                                                                    {isBooking && <ActivityIndicator className='margin-top-ten' size="small" color="#00A1DE" />}
                                                                                    {
                                                                                        BookApp && BookApp.length && appointSlots.AMslots.length ?

                                                                                                // selectedReasonForVisitId && selectedReasonForVisitId === "163799" ?


                                                                                            appointSlots.AMslots.map((item, index) => {

                                                                                                return (
                                                                                                    <li className='margin-top-ten appoint-slot-am' >

                                                                                                        {/* id={"m" + index} */}
                                                                                                        <Tooltip title={Colorlist.find(x => x.id === item.resource.extension[5].valueString).name}>

                                                                                                            <span id={"m" + index} onClick={() => addClassTest(`${index}`, item, index)} className="timing addclasstest d-flex" >
                                                                                                                <span>{FormatAMPM(item.resource?.extension?.find(o => o.url === "StartTimeString")?.valueString)}
                                                                                                                </span>

                                                                                                                {
                                                                                                                    Colorlist && Colorlist.length > 1 ?
                                                                                                                        <div className={'newudpate-color-div appoint-color-code-' + Colorlist.find(x => x.id === item.resource.extension[5].valueString).colorcode}></div>
                                                                                                                        : null}

                                                                                                            </span>
                                                                                                        </Tooltip>
                                                                                                    </li>
                                                                                                )
                                                                                            })
                                                                                                // :

                                                                                                // BookApp.map((item, index) => {

                                                                                                //     return (
                                                                                                //         <li className='margin-top-ten appoint-slot-am' >

                                                                                                //             {/* id={"m" + index} */}
                                                                                                //             <Tooltip title={Colorlist.find(x => x.id === item.resource.extension[5].valueString).name}>

                                                                                                //                 <span id={"m" + index} onClick={() => addClassTest(`${index}`, item, index)} className="timing addclasstest d-flex" >
                                                                                                //                     <span>{FormatAMPM(item.resource.start.split('+')[0])}
                                                                                                //                     </span>

                                                                                                //                     {
                                                                                                //                         Colorlist && Colorlist.length > 1 ?
                                                                                                //                             <div className={'newudpate-color-div appoint-color-code-' + Colorlist.find(x => x.id === item.resource.extension[5].valueString).colorcode}></div>
                                                                                                //                             : null}

                                                                                                //                 </span>
                                                                                                //             </Tooltip>
                                                                                                //         </li>
                                                                                                //     )
                                                                                                // })
                                                                                            :
                                                                                            !isBooking ?


                                                                                                <div className='alert alert-danger p-2 text-center mb-0'>{sameDayErrorMsg !== null ? sameDayErrorMsg : "No Slots Available"}</div> : null

                                                                                    }
                                                                                </div>

                                                                            </div>



                                                                        </div>


                                                                        <div className="col-md-6 mt-2 ">
                                                                            <div className="card">
                                                                                <div className='card-header'>
                                                                                    <h6 className='mb-0'>Afternoon</h6>
                                                                                </div>
                                                                                <div className='card-body  p-2'>
                                                                                    {isBooking && <ActivityIndicator className='margin-top-ten' size="small" color="#00A1DE" />}
                                                                                    {
                                                                                        BookAppPM && BookAppPM.length && appointSlots.PMslots.length ?
                                                                                                // selectedReasonForVisitId && selectedReasonForVisitId === "163799" ?

                                                                                            appointSlots.PMslots.map((item, index) => {

                                                                                                return (
                                                                                                    <li className='margin-top-ten appoint-slot-pm' >
                                                                                                        <Tooltip title={Colorlist.find(x => x.id === item.resource.extension[5].valueString).name}>


                                                                                                            <span h id={"ma" + index} onClick={() => addClassTest('a' + index, item, index)}
                                                                                                                className="timing timing-anchor d-flex" >
                                                                                                                <span>{FormatAMPM(item.resource?.extension?.find(o => o.url === "StartTimeString")?.valueString)}
                                                                                                                </span>
                                                                                                                {
                                                                                                                    Colorlist && Colorlist.length > 1 ?
                                                                                                                        <div className={'appointment-color-list appoint-color-code-' + Colorlist.find(x => x.id === item.resource.extension[5].valueString).colorcode}></div>
                                                                                                                        : null}
                                                                                                            </span>
                                                                                                        </Tooltip>
                                                                                                    </li>

                                                                                                )
                                                                                                    //  }

                                                                                            })
                                                                                                // :
                                                                                                // BookAppPM.map((item, index) => {

                                                                                                //     return (
                                                                                                //         <li className='margin-top-ten appoint-slot-pm' >
                                                                                                //             <Tooltip title={Colorlist.find(x => x.id === item.resource.extension[5].valueString).name}>


                                                                                                //                 <span h id={"ma" + index} onClick={() => addClassTest('a' + index, item, index)}
                                                                                                //                     className="timing timing-anchor d-flex" >
                                                                                                //                     <span>{FormatAMPM(item.resource.start.split('+')[0])}
                                                                                                //                     </span>
                                                                                                //                     {
                                                                                                //                         Colorlist && Colorlist.length > 1 ?
                                                                                                //                             <div className={'appointment-color-list appoint-color-code-' + Colorlist.find(x => x.id === item.resource.extension[5].valueString).colorcode}></div>
                                                                                                //                             : null}
                                                                                                //                 </span>
                                                                                                //             </Tooltip>
                                                                                                //         </li>

                                                                                                //     )
                                                                                                //     //  }

                                                                                                // })
                                                                                            :
                                                                                            !isBooking &&
                                                                                            <div className='alert alert-danger p-2 text-center mb-0'>{sameDayErrorMsg !== null ? sameDayErrorMsg : "No Slots Available"}</div>

                                                                                    }
                                                                                </div>
                                                                            </div>


                                                                        </div>

                                                                    </div>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                {
                                                    isselectedreason &&

                                                    <div className="col-md-12">


                                                    </div>
                                                }
                                            </>

                                        }
                                    </>
                                }
                                {telemedicinePartnerData.length > 0 ? <div className='telemed-alternatives'>

                                    <div className='row bg-primary p-3'>
                                        <span className='fw-bolder'>

                                            Other Telemedicine Consultation Services
                                        </span>

                                    </div>
                                    <div className='row m-2'>

                                        {telemedicinePartnerData.length > 0 ? telemedicinePartnerData.map(partner => {
                                            return <Tooltip title={partner.PartnerName}><div className="telemed-partner-box m-2" onClick={() => {
                                                setSelectedTelemedicinePartner(partner)
                                                validatePartnerTeleHealth(partner.PartnerUUId)
                                            }}>
                                                <img className="telemed-partner-logo" src={partner.logoimage} />

                                                <p className='m-0'>{partner.PartnerName}</p>
                                            </div></Tooltip>
                                        }) : null}
                                    </div>
                                </div> : null}

                                {!isSelectedBook ?
                                    <TouchableOpacity
                                        className='booking-appointment-touch-opacity'

                                        onPress={() => { setIsSelectedBook(true) }}>
                                        <Text className='text-white font font-size-15px' >Book an Appointment</Text>
                                    </TouchableOpacity>
                                    :
                                    null
                                }
                            </>
                            : <div className='alert alert-danger p-2 text-center'>Appointment Types must be configured in Portal Settings</div>
                            :
                            <ActivityIndicator size="small" color="#00A1DE" />
                        }


                    </div>




                </div>

            }
            <Space direction="vertical"></Space>
            <Modal
                title="Consent"
                open={consentTeleHealthModalVisible}
                onOk={() => {
                    if (typeof teleHealthValidateResponse !== "string" && teleHealthValidateResponse?.URL !== "") {
                        window.open(teleHealthValidateResponse?.URL, "_blank")
                        setConsentTeleHealthModalVisible(false)
                    }
                }}
                onCancel={() => setConsentTeleHealthModalVisible(false)}
                maskClosable={false}
                afterClose={() => {
                    setSelectedTelemedicinePartner(null)
                    setTeleHealthValidateResponse(null)
                }}
            >
                <p>You are now leaving the {selectedTelemedicinePartner ? selectedTelemedicinePartner?.PracticeName : null} mylndici portal and will be redirected to the {selectedTelemedicinePartner ? selectedTelemedicinePartner?.PartnerName : null} booking portal.<br /><br />
                    By continuing, you agree to share your demographics and contact information with {selectedTelemedicinePartner ? selectedTelemedicinePartner?.PartnerName : null} and acknowledge that you will be leaving mylndici platform and accessing services provided by {selectedTelemedicinePartner ? selectedTelemedicinePartner?.PartnerName : null}</p>
            </Modal>
            <Modal
                title="Book Your Appointment"
                centered
                open={showReset}
                onOk={() => {
                    handleConfirmAppoint()
                    // saveApi()
                    // setAddContactVisible(false)
                }}
                onCancel={() => handleCloseReset()}

                className='appointment-booking-modal'
                width={1000}
            >
                <div className="row" >
                    <div className="col-md-12 mx-auto">


                        <div className="form-group row mb-0  margin-top-twenty">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Appointment Id</label>
                                <div className="input-group">
                                    <input autoComplete="off" id="mHouse" type="text" value={slotId} readOnly className="form-control" placeholder="Find Address" />

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Doctor Name:</label>
                                <div className="input-group">
                                    <input autoComplete="off" id="mHouse" type="text" value={doctorName} readOnly className="form-control" placeholder="House Number" />

                                </div>
                            </div>

                        </div>

                        {/* Street information */}
                        <div className="form-group row mb-0 margin-top-twenty">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Appointment Duration</label>
                                <div className="input-group">
                                    <input autoComplete="off" id="mFloor" type="text" value={durationAppoint} readOnly className="form-control" placeholder="Floor" />

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Appointment Time </label>
                                <div className="input-group">
                                    <input autoComplete="off" id="mHouse" type="text" value={appointTime} readOnly className="form-control" placeholder="House Number" />

                                </div>
                            </div>
                            {/* <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Reason</label>
                                <div className="input-group">
                                    <input autoComplete="off" id="mStreet" type="text" className="form-control" placeholder="Street" />

                                </div>
                            </div> */}
                        </div>

                        {/* City information */}



                        {
                            isAdding && <ActivityIndicator className='margin-top-thirty' size="small" />
                        }



                    </div>




                </div>

            </Modal>

            <Modal
                title="COVID-19 (NOVEL CORONAVIRUS)"
                open={isCovidModalVisible}
                onOk={handleCovidModalOk}
                onCancel={handleCovidModalCancel}
                width={1000}>
                <div className='text-center bg-secondary p-3 mb-2 font-weight-600 font-size-19'>This information is being captured for practice use only.</div>
                <h6 id="covidTitle">Symptoms</h6>
                <span>These symptoms do not necessarily mean you have COVID-19 but it is important for the health and wellbeing of our staff and community that we look out for each other and inform the practice if you have any of the below symptoms.</span>
                <p className='mt-2'>Have you become unwell in the last 7 days with any of the following ?</p>

                <div className="form-group mt-2">

                    <div className="row mt-2 ml-1">

                        <Checkbox.Group options={['Cough', 'Shortness of breath', 'Sore throat', 'Fever (38C or higher)', 'Muscle aches', 'Runny Nose', 'Fatigue', 'Loss of Smell', 'Altered Taste']} value={symptoms} onChange={onChangeSymptom} />
                        {/* <Checkbox >Cough</Checkbox>
                        <Checkbox >Shortness of breath</Checkbox>
                        <Checkbox >Sore throat</Checkbox>
                        <Checkbox >Fever (38C or higher)</Checkbox> */}
                        <Checkbox checked={nosymptoms} onChange={onChangeNoSympton}>No Symptoms</Checkbox>
                    </div>
                </div>

                {/* <div className="form-group mt-2">
                    <h6 id="covidTitleB">Recent travel or contact with someone with novel coronavirus</h6>
                    <hr />
                    <p id="travelA">a) Have you travelled to or from (excluding airport transit through) countries or areas of concern* within 14 days before onset of illness?</p>

                    <div className="row mt-2 ml-1">
                        <div className="col">
                            <div>
                                <Radio.Group onChange={onTravelChange} value={travelcheck}>
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={2}>No</Radio>

                                </Radio.Group>
                            </div>
                            {
                                travelcheck == 1 &&
                                <>
                                    <p className="mt-4">If YES, please provide details</p>
                                    <input autoComplete="off" id="mtravel" className="form-control" placeholder="Enter details here..." />

                                </>}

                        </div>
                    </div>
                    <div className="mt-4"></div>
                    <p id="travelB">b) Have you been in close contact or casual contact** with a person with suspect, probable or confirmed infection of novel coronavirus in the 14 days before the onset of your symptoms?*</p>
                    <div className="row mt-2 ml-1">
                        <div className="col">
                            <div>
                                <Radio.Group onChange={onContactWithSickChange} value={contactCheck} >
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={2}>No</Radio>

                                </Radio.Group>
                            </div>
                            {
                                contactCheck == 1 &&
                                <>
                                    <p className="mt-4">If YES, please provide details</p>
                                    <input autoComplete="off" id="mcontact" className="form-control" placeholder="Enter details here..." />
                                </>
                            }
                        </div>
                    </div>
                </div> */}


                {/* ----------------------------------------------- */}
                <div className="form-group mt-2">
                    {/* <h6 id="covidTitleB">Consent</h6> */}
                    <hr />
                    {/* <p id="travelA">a) Are you in isolation awaiting Covid19 result?</p>
                    <div className="row mt-2 ml-1">
                        <div className="col">
                            <div>
                                <Radio.Group onChange={onChangeConsentA} value={coivdDose}>
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={2}>No</Radio>

                                </Radio.Group>
                            </div>

                        </div>
                    </div> */}


                    <div className="mt-4"></div>
                    <p id="travelB">Have you or any of your household or close contacts, tested positive for Covid-19 in the last 7 days?</p>
                    <div className="row mt-2 ml-1">
                        <div className="col">
                            <div>
                                <Radio.Group onChange={onChangeConsentB} value={wearMask} >
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={2}>No</Radio>

                                </Radio.Group>
                            </div>
                            {/* {
                                contactCheck == 1 &&
                                <>
                                    <p className="mt-4">If YES, please provide details</p>
                                    <input autoComplete="off" id="mcontact" className="form-control" placeholder="Enter details here..." />
                                </>
                            } */}
                        </div>
                    </div>
                </div>


            </Modal>

            <Modal
                title="Confirm Appointment"
                open={confirmModalvisible}
                onOk={confirmAppointHandleOk}

                confirmLoading={confirmLoading}
                onCancel={() => {
                    LockUnlockSlot(slotId, false)
                    confirmhandleCancel()

                }}
                maskClosable={false}
                closable={!confirmLoading}
                cancelButtonProps={{ disabled: confirmLoading }}
                afterClose={() => {
                    setConfirmLoading(false)
                }}
            >


                <div className='confirmation-appt-class'><b>DateTime:</b> {moment(selectedDate).format('ll')}, {appointTime}</div>

                <div className='confirmation-appt-class'><b>Type:</b> {typeName}</div>

                <div className='confirmation-appt-class'><b>Location:</b> {locationName}</div>
            </Modal>
            <Modal
                title="Confirm Appointment"
                open={ErrorModal}
                onOk={() => { setErrorModal(false) }}

                // confirmLoading={confirmLoading}
                onCancel={() => { setErrorModal(false) }}
            >
                <span>
                    As per the responses recorded through this questionnaire, your appointment cannot be booked. For further assistance please contact the practice to organize/manage your appointment.
                </span>
            </Modal>

            <Modal
                title="Confirm Appointment"
                open={confirmModalvisible2}
                onOk={confirmAppointHandleOk2}
                width={1000}
                onCancel={confirmhandleCancel2}
            >
                <span>
                    {`Based upon provided input on COVID-  19 it is suggested to contact health line 0800 358 5453. Please select one of the following two options. If you still want to book an appointment ${GetAppointmentDate(selectedDate) + "-" + GetMonthName(selectedDate) + "-" + GetYear(selectedDate)} ${appointTime} at ${locationName}. Please confirm.`}
                </span>
                <span> Please select one of the following two options</span>
                {/*  */}
                <Radio.Group onChange={onBookingTypeChange} value={bookingtype}>
                    <Radio value={1}>Virtual consult</Radio>
                    <Radio value={2}>Still wish to book Face 2 Face </Radio>
                </Radio.Group>

                {
                    bookingtype == 2 ?
                        <div>
                            <hr />
                            <span className="mt-4">{portalSetting && portalSetting.Face2FaceConfirmationMessage?.split(",")[0]}</span>

                        </div>
                        :
                        AppointmentTypes && AppointmentTypes.length ?
                            <Select
                                //  defaultValue={AppointmentTypes[0].resource.extension[1].valueString}
                                //defaultActiveFirstOption={true}
                                defaultActiveFirstOption
                                showSearch
                                className='w-100 margin-top-ten'
                                placeholder="Select Appointment type"
                                optionFilterProp="children"
                                onChange={onAppointTypeSelectChange2}
                                // onFocus={onFocus}
                                //onBlur={onBlur}
                                //onSearch={onSearch}
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                            >
                                {AppointmentTypes.map((person, index) => {
                                    if (person.resource.extension[1].valueString.includes('Virtual'))
                                        return (
                                            <Option value={person.resource.extension[0].valueString}>{person.resource.extension[1].valueString}</Option>
                                        )
                                })}

                            </Select>
                            : null
                }

            </Modal>
        </div >

    );
}

function mapStateToProps(state) {
    return {
        AvailablePractitioner: state.AvailablePractitioner,
        Roles: state.Roles,
        BookApp: state.BookApp,
        Proxy: state.Proxy,
        AddAppointment: state.AddAppointment,
        FutureAppointments: state.FutureAppointments,
        AppointmentTypes: state.AppointmentTypes,
        ProviderLocation: state.ProviderLocation,
        LookUpList: state.LookUpList,
    }
}

function mapDispatchToProps(disptach) {
    return {
        getLookupList: () => disptach(fetchLookupListAPI()),
        getPractitioner: (roleID, appType, roleType, locations, selectedReasonForVisitId) => disptach(fetchAvailablePractitionerFromApi(roleID, appType, roleType, locations, selectedReasonForVisitId)),
        getRoles: (defaultLocation) => disptach(fetchRolesFromApi(defaultLocation)),
        getApp: (def, id, typeid, locations, sameDayBooking) => disptach(fetchTestAllergy(def, id, typeid, locations, sameDayBooking)),
        getAppCleanUp: () => disptach(clenupbooking()),
        getPractitionerClean: () => disptach(getPractitionerClean()),
        addAppoint: (slotId, testing, PaitientID, typeid, reasonId, startTime, endTime, duration) => disptach(addPaitentAppoinmenttoAPI(slotId, testing, PaitientID, typeid, reasonId == null ? 25 : reasonId, startTime, endTime, duration)),
        addAppointClean: () => disptach(clenuplatData()),
        getAppoint: (pageSize, pageNumber) => disptach(fetchfAppointmentsFromApi(pageSize, pageNumber)),
        getAppointType: () => disptach(fetchAppointmentsTypesFromApi()),
        getProviderLocation: () => disptach(fetchProviderLocationFromApi()),
    }
}





export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewUpdatedAppointment)

//export default FutureAppointmentView